<template>
  <!-- 侧边导航 -->
  <div class="aside-container">
    <el-aside :width="settings.width">
      <h1>{{settings.asideTitle}}</h1>
      <el-menu :default-active="$route.path" :collapse="settings.collapse" active-text-color="#F8FAFB" background-color="#1A1C1E" text-color="#74767A" router>
        <el-menu-item index="/home">
          <i class="el-icon-s-home"></i>
          <span>首页</span>
        </el-menu-item>
        <el-menu-item index="/specimen">
          <i class="el-icon-s-data"></i>
          <span>标本管理</span>
        </el-menu-item>
        <el-menu-item index="/search">
          <i class="el-icon-search"></i>
          <span>标本搜索</span>
        </el-menu-item>
        <el-menu-item index="/user" v-if="isAuth('0')">
          <i class="el-icon-s-operation"></i>
          <span>用户管理</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: () => {
        return {
          collapse: false,
          width: '170px'
        }
      }
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="less" scoped>
.aside-container {
  .el-aside {
    height: 100%;
    background-color: #1a1c1e;
    color: #74767a;
    h1 {
      color: #fff;
      text-align: center;
      font-size: 22px;
    }
    .el-menu {
      border: none;
    }
  }
}
</style>