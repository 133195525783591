<template>
  <div class="main-layout">
    <el-container>
      <Aside :settings="asideSettings" />
      <el-container>
        <el-header>
          <div class="my-icon">
            <i class="el-icon-s-fold" v-show="!asideSettings.collapse" @click="collapseAside"></i>
            <i class="el-icon-s-unfold" v-show="asideSettings.collapse" @click="collapseAside"></i>
          </div>
          <div class="toolbar">
            <span style="font-size:16px">{{roleName}}</span>
            <span style="margin:15px;font-size:16px">{{ pName }}</span>
            <span>
              <el-button style="font-size:14px" type="danger" size="mini" @click="LogOut">退出</el-button>
            </span>
          </div>
        </el-header>
        <el-main class="main-content">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Aside from './Aside.vue'
import { logout } from '@/api/user'
export default {
  components: {
    Aside
  },
  data() {
    return {
      asideSettings: {
        collapse: false,
        width: '170px',
        asideTitle: '实验室管理'
      },
      pName: localStorage.getItem('name'),
      roleName: localStorage.getItem('role') === '0' ? '超级管理员' : localStorage.getItem('role') === '1' ? '管理员' : '用户'
    }
  },
  methods: {
    collapseAside() {
      this.asideSettings.collapse = !this.asideSettings.collapse
      this.asideSettings.width = this.asideSettings.collapse ? '64px' : '170px'
      this.asideSettings.asideTitle = this.asideSettings.collapse ? '管理' : '实验室管理'
    },
    LogOut() {
      logout().then((value) => {
        localStorage.removeItem('token')
        localStorage.removeItem('userName')
        localStorage.removeItem('name')
        localStorage.removeItem('role')
        // 提示
        this.$message({
          message: '退出成功',
          type: 'success'
        })
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-layout {
  height: 100%;
}
.el-container {
  height: 100%;
  .el-header {
    background-color: #409eff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .my-icon {
      font-size: 25px;
      cursor: pointer;
    }
    .toolbar {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      right: 20px;
      text-align: right;
    }
  }
}
</style>